import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'environments/environment';
import { LocalService } from './local.service';
import { User, KEY_AUTH_USER, JwtAccessToken } from 'app/interfaces/user';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  authUser: User|null = null;

  constructor(
    public local: LocalService,
    private http: HttpClient
  ) { }

  /**
   * Save data User
   * @param user 
   */
  saveAuthUser() {

    if (this.authUser == null) {

      this.removeAuthUser();
    
    } else {

      this.local.saveData(KEY_AUTH_USER, JSON.stringify(this.authUser));
    }
  }

  /**
   * Get data User
   * @returns 
   */
  getAuthUser(): User|null {

    const data = this.local.getData(KEY_AUTH_USER);

    if (data == null) {

      this.authUser = null;

      return null;
    }

    const user: User = JSON.parse(data);

    this.authUser = user;

    return user;
  }

  /**
   * Remove data User
   */
  removeAuthUser() {

    this.authUser = null;

    this.local.removeData(KEY_AUTH_USER);
  }

  /**
   * Invia rihiesta per aggiornare il token di autenticazione 
   */
  userAuthToken() {

    let url = API_URL + "/api/conad/admin/refresh";

    let params = {refreshToken: (this.authUser) ? this.authUser.auth.refreshToken : ""};

    return this.http.post<JwtAccessToken>(url, params).pipe(
      tap((data) => {

        if (this.authUser != null) {

          this.authUser.auth = {
            jwtToken: data.jwtToken,
            refreshToken: data.refreshToken
          };
  
          this.saveAuthUser();
        }
      })
    );
  }

  /**
   * Login utente ADMIN
   * @param username 
   * @param password
   * @returns 
   */
  adminLogin(username: string, password: string) {

    const body = { "username": username, "password": password };

    return this.http.post<JwtAccessToken>(API_URL + "/api/conad/admin/login", body);
  }

  /**
   * Dati utente associato alla fidelityCard
   * @param fidelityCard 
   * @returns 
   */
  adminGetInfoUser(fidelityCard: string) {

    const body = { "fidelityCard": fidelityCard };

    return this.http.post<any>(API_URL + "/api/game/games/getInfoUser", body);
  }

  /**
   * Dati memorizzati
   * @param dataKey 
   * @returns 
   */
  adminGetDataValue(dataKey: string[]) {

    const body = { "dataKey": dataKey };

    return this.http.post<any>(API_URL + "/api/conad/admin/getDataValue", body);
  }

}
