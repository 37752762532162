<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
        <!--
        <div class="navbar-wrapper">
          <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        -->
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">

            <div *ngIf="appService.authUser" class="dropdown header_user_option">
                <button href="#" class="btn dropdown-toggle btn-round" data-toggle="dropdown" aria-expanded="true" >
                    <i class="material-icons">person</i> &nbsp;
                    {{appService.authUser.username}}
                    <b class="caret"></b>
                </button>
                <ul class="dropdown-menu">
                    <li><a href="javascript:void(0)" (click)="onClickLogout()">Esci</a></li>
                </ul>
            </div>
            
        </div>
    </div>
</nav>