import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/services/app.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    //{ path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    { path: '/user-login', title: 'User Login',  icon:'login', class: ''},
    { path: '/user-search', title: 'User Search',  icon:'search', class: '' },
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/trends', title: 'Trends',  icon: 'insights', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(public appService: AppService, private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  /**
   * Funzione per effettuare il logout
   */
  onClickLogout() {

    this.appService.removeAuthUser();

    this.router.navigateByUrl('/user-login');
  }
}
