import { Component} from '@angular/core';
import { AppService } from './services/app.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    public appService: AppService
  ) {

    // init utente loggato
    this.appService.getAuthUser();

    if (this.appService.authUser == null) {

      // TODO: redirect su login
    }
  }

}
