<div class="logo">

    <img class="logo_side_bar" src="https://www.conad.it/assets/icons/conad-logo.svg" alt="" >

    <span class="sidebar_nome_concorso">Le sfide di Conad</span>

    <!--
    <a href="https://www.creative-tim.com" class="simple-text">
        <div class="logo-img">
            <img src="/assets/img/angular2-logo-red.png"/>
        </div>
        Creative Tim
    </a>
    -->
</div>
<div class="sidebar-wrapper">
    
    <div *ngIf="appService.authUser && isMobileMenu()">
    
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">{{appService.authUser.username}}</span>
                    </p>
                </a>
            </li>
        </ul>

    </div>

    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>

        <li *ngIf="appService.authUser && isMobileMenu()" class="nav-item">
            <a class="nav-link" href="javascript:void(0)" (click)="onClickLogout()">
                <i class="material-icons">logout</i>
                <p>Esci</p>
            </a>
        </li>
    </ul>
</div>
