export const KEY_AUTH_USER = "auth_user";

export interface User {

    username: string;
    
    auth: JwtAccessToken;
}

export interface JwtAccessToken {

    jwtToken: string;
    
    refreshToken: string;
}